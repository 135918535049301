import React, { useRef } from 'react';
import styled from 'styled-components/macro';
import PropTypes from 'prop-types';
import { colors } from '../../Theme';
import icClose from '../../Assets/Images/ic-close.svg';
import ModalWrapper from '../../Containers/ModalWrapper';
import useOnClickOutside from '../../Hooks/useOnClickOutside';
import { useRematchDispatch, useSelect } from '../../Hooks';
import { history } from '../../Store';

const NotificationStyled = styled.div`
  width: 36rem;
  padding: 3.8rem 3.2rem 2.4rem;
  background-color: ${colors.white};
  position: relative;
  .cp-close {
    position: absolute;
    right: 0.8rem;
    top: 0.8rem;
    width: 3rem;
    height: 3rem;
    border: 0;
    background-color: transparent;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    img {
      width: 1.2rem;
    }
  }
  .cp-body {
    line-height: 2rem;
    font-size: 1.4rem;
    margin-bottom: 2.4rem;
    color: ${colors.black};
  }
  .cp-foot {
    text-align: right;
    button {
      background-color: ${colors.yellow};
      color: ${colors.blue};
      font-size: 1.4rem;
      font-weight: bold;
      padding: 0.9rem 3.2rem;
      border: 0;
      outline: none;
    }
  }
`;

export const Notification = props => {
  const { isOpen, message, btnText, btnLink } = useSelect(
    ({ notification }) => ({
      isOpen: notification.isOpen,
      message: notification.message,
      btnText: notification.btnText,
      btnLink: notification.btnLink,
    }),
  );

  const { closeAsync } = useRematchDispatch(({ notification }) => ({
    closeAsync: notification.closeAsync,
  }));
  const close = () => {
    closeAsync();
  };

  const goToLink = () => {
    history.push(btnLink);
    closeAsync();
  };
  const { className } = props;
  const ref = useRef();
  useOnClickOutside(ref, close);

  return (
    <div className={className}>
      {isOpen && (
        <ModalWrapper>
          <div ref={ref}>
            <NotificationStyled>
              <button className="cp-close" onClick={close}>
                <img src={icClose} alt="icClose" />
              </button>
              <div className="cp-body">{message}</div>
              <div className="cp-foot">
                <button
                  className="cp-success"
                  onClick={btnLink ? goToLink : close}
                >
                  {btnText ? btnText : 'OK'}
                </button>
              </div>
            </NotificationStyled>
          </div>
        </ModalWrapper>
      )}
    </div>
  );
};

Notification.propTypes = {
  className: PropTypes.string,
  closeAsync: PropTypes.func,
};
Notification.defaultProps = {
  className: '',
};
