const DefinedColors = {
  black: '#000000',
  black10: 'rgba(0, 0, 0, 0.10)',
  black15: 'rgba(0, 0, 0, 0.15)',
  black30: 'rgba(0, 0, 0, 0.30)',
  black45: 'rgba(0, 0, 0, 0.45)',
  black65: 'rgba(0, 0, 0, 0.65)',
  black95: 'rgba(0, 0, 0, 0.95)',
  blue: '#007bff',
  blue15: 'rgba(161, 194, 250, 0.15)',
  white: '#fff',
  gray: '#919191',
  grayLight: '#fafafa',
  grayDark: '#4f4f4f',
  red: '#da4336',
};

export const Theme = {
  color: {
    primary: DefinedColors.blue,
    secondary: DefinedColors.grayLight,
    ...DefinedColors,
  },
};

export default Theme;
