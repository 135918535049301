import React, { useState } from 'react';
import { IntlProvider } from 'react-intl';
import { IntlContext } from './IntlContext';
import useLocalStorage from 'Hooks/useLocalStorage';
import { LOCALE_STORAGE_KEY } from './constants';
import { DEFAULT_LOCALE, translationMessages } from 'i18n';

export const IntlProviderWrapper = ({ children }) => {
  const [localLocale, setLocalLocale] = useLocalStorage(
    LOCALE_STORAGE_KEY,
    DEFAULT_LOCALE,
  );
  const [state, setState] = useState({
    changeLang,
    locale: DEFAULT_LOCALE,
    messages: translationMessages[localLocale || DEFAULT_LOCALE],
  });

  function changeLang(locale) {
    setState({ ...state, locale, messages: translationMessages[locale] });
    setLocalLocale(locale);
  }

  return (
    <IntlContext.Provider value={state}>
      <IntlProvider
        key={state.locale}
        locale={state.locale}
        messages={state.messages}
        defaultLocale={DEFAULT_LOCALE}
      >
        {children}
      </IntlProvider>
    </IntlContext.Provider>
  );
};
