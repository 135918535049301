import {
  getApplicationById,
  createApplication,
  getApplications,
  updateApplication,
  createCANAccount,
} from '../../Services/application';
import { get } from 'lodash';
import { push } from 'connected-react-router';
import { getAvatar, uploadFile } from '../../Services/file';

const applicationInit = {
  currentApplication: {},
  applications: [],
};

export const application = {
  state: applicationInit, // initial state
  reducers: {
    updateAppIcon(state, payload) {
      return {
        ...state,
        currentApplication: { ...state.currentApplication, app_icon: payload },
      };
    },
    updateAppFavicon(state, payload) {
      return {
        ...state,
        currentApplication: {
          ...state.currentApplication,
          app_favicon: payload,
        },
      };
    },

    updateCurrentApplication(state, payload) {
      return { ...state, currentApplication: payload };
    },
    updateListApplications(state, payload) {
      return { ...state, applications: payload };
    },
    clear() {
      return { applicationInit };
    },
  },
  effects: dispatch => ({
    async createApp(action) {
      try {
        const { applicationName, applicationUrl } = action;
        const data = await createApplication(applicationName, applicationUrl);
        dispatch.notification.openAsync('Create application successfully!');
        dispatch(push(`/?appId=${data.application_id}`));
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
      }
    },
    async getApplicationsAsync() {
      try {
        const data = await getApplications();
        dispatch.application.updateListApplications(data);
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
      }
    },
    async fetchCurrentApplication(applicationId) {
      try {
        const data = await getApplicationById(applicationId);
        dispatch.application.updateCurrentApplication(data);
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        console.error(e);
      }
    },
    async uploadImageToS3(file) {
      try {
        const uploadedFile = await uploadFile(file);
        const data = await getAvatar(uploadedFile);
        dispatch.application.updateAppIcon(data);
      } catch (e) {
        console.error(e);
      }
    },
    async uploadFaviconToS3(file) {
      try {
        const uploadedFile = await uploadFile(file);
        const data = await getAvatar(uploadedFile);
        dispatch.application.updateAppFavicon(data);
      } catch (e) {
        console.error(e);
      }
    },
    async updateApplicationInfo(action) {
      try {
        const {
          application_id,
          application_name,
          application_url,
          app_icon,
          app_favicon,
          theme,
          policy_url,
          terms_of_service_url,
          auth_kakao_client_id,
          auth_kakao_client_secret,
          is_consent_display,
          is_14_years_old_required,
          consent_type,
          auth_google_client_id,
          auth_google_client_secret,
          auth_providers,
          auth_methods,
          version,
          background_color,
          metamask_sign_message,
          auth_apple_client_id,
          auth_apple_team_id,
          auth_apple_secret_key_id,
          auth_apple_client_secret,
          auth_facebook_client_id,
          auth_facebook_client_secret,
          auth_discord_client_id,
          auth_discord_client_secret,
          auth_twitter_client_id,
          auth_twitter_client_secret,
        } = action;
        const data = await updateApplication(
          application_id,
          application_name,
          application_url,
          app_icon,
          app_favicon,
          theme,
          policy_url,
          terms_of_service_url,
          auth_kakao_client_id,
          auth_kakao_client_secret,
          is_consent_display,
          is_14_years_old_required,
          consent_type,
          auth_google_client_id,
          auth_google_client_secret,
          auth_providers,
          auth_methods,
          version,
          background_color,
          metamask_sign_message,
          auth_apple_client_id,
          auth_apple_team_id,
          auth_apple_secret_key_id,
          auth_apple_client_secret,
          auth_facebook_client_id,
          auth_facebook_client_secret,
          auth_discord_client_id,
          auth_discord_client_secret,
          auth_twitter_client_id,
          auth_twitter_client_secret,
        );

        dispatch.application.updateCurrentApplication(data);
        dispatch.notification.openAsync('Update client successfully');
      } catch (e) {
        console.log(e);
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        console.error(e);
      }
    },
    async clearStore() {
      dispatch.application.clear();
    },
    async createCANAccountAsync(appId) {
      try {
        const data = await createCANAccount(appId);
        if (data) {
          dispatch.notification.openAsync('Create account successfully!');
          dispatch.application.fetchCurrentApplication(appId);
        }
      } catch (e) {
        const res = get(e.response, 'data');
        if (res) {
          if (res === "This Application doesn't have API key") {
            dispatch.notification.openCustomAsync({
              message:
                'This Application does not have API key. Please create API key first.',
              btnText: 'Create API key',
              btnLink: `/oauth2/clients?appId=${appId}`,
            });
          } else if (
            res === 'This Application requested creating an CAN Account'
          ) {
            dispatch.notification.openAsync(
              'This Application requested creating an CAN Account. Please try again after 5 seconds',
            );
          } else {
            dispatch.notification.openAsync(res);
          }
        }
        console.error(e);
      }
    },
  }),
  selectors: slice => ({
    currentApplicationSelector() {
      return slice(state => get(state, 'currentApplication'));
    },
    applicationsSelector() {
      return slice(state => get(state, 'applications'));
    },
  }),
};
