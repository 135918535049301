import {
  createApiKeys,
  deleteApiKeyByAppIdKeyId,
  getApiKeysByAppId,
} from 'Services/apiKey';
import { get } from 'lodash';

const apiKeyInit = {
  apiKeys: [],
};

export const apiKey = {
  state: apiKeyInit, // initial state
  reducers: {
    updateApiKeys(state, payload) {
      return { ...state, apiKeys: payload };
    },
    deleteAnApiKeys(state, payload) {
      return {
        ...state,
        apiKeys: state.apiKeys.filter(apiKey => apiKey.id !== payload),
      };
    },
    addAnApiKey(state, payload) {
      return { ...state, apiKeys: [...state.apiKeys, payload] };
    },
    clear() {
      return { apiKeyInit };
    },
  },
  effects: dispatch => ({
    async createApiKeys(appId) {
      try {
        const data = await createApiKeys(appId);
        dispatch.apiKey.addAnApiKey(data);
        console.log(dispatch.apiKey);
      } catch (error) {
        dispatch.notification.openAsync(
          get(error.response, 'data.error_description'),
        );
        console.error(error);
      }
    },
    async fetchApiKeys(applicationId) {
      try {
        const data = await getApiKeysByAppId(applicationId);
        dispatch.apiKey.updateApiKeys(data);
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        console.error(e);
      }
    },
    async deleteApiKeyByAppIdKeyId({ appId, keyId }) {
      try {
        await deleteApiKeyByAppIdKeyId({ appId, keyId });
        dispatch.apiKey.deleteAnApiKeys(keyId);
      } catch (error) {
        dispatch.notification.openAsync(
          get(error.response, 'data.error_description'),
        );
        console.error(error);
      }
    },
    async clearStore() {
      dispatch.client.clear();
    },
  }),

  selectors: slice => ({
    apiKeySelectors() {
      return slice(state => get(state, 'apiKeys'));
    },
  }),
};
