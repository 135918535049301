export const notification = {
  state: {
    isOpen: false,
    message: null,
    btnText: '',
    btnLink: '',
  },
  reducers: {
    open(state, payload) {
      return { ...state, isOpen: true, message: payload };
    },
    openCustom(state, payload) {
      return { ...state, isOpen: true, ...payload };
    },
    close(state) {
      return {
        ...state,
        isOpen: false,
        message: null,
        btnText: '',
        btnLink: '',
      };
    },
  },
  effects: dispatch => ({
    async openAsync(message) {
      dispatch.notification.open(message ? message : 'Error');
    },
    async openCustomAsync(data) {
      dispatch.notification.openCustom(data);
    },
    async closeAsync() {
      dispatch.notification.close();
    },
  }),
  selectors: slice => ({
    message() {
      return slice(state => state.message);
    },
    isOpen() {
      return slice(state => state.isOpen);
    },
    btnText() {
      return slice(state => state.btnText);
    },
    btnLink() {
      return slice(state => state.btnLink);
    },
  }),
};
