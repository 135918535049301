import { useSelect } from 'Hooks';
import React from 'react';
import { Route, Redirect } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const { isGettingCurrentSession, isLoggedIn } = useSelect(
    ({ credentials }) => ({
      isGettingCurrentSession: credentials.isGettingCurrentSessionSelector,
      isLoggedIn: credentials.isLoggedInSelector,
    }),
  );

  if (!isGettingCurrentSession && !isLoggedIn) return <Redirect to="/login" />;
  return <Route {...rest} render={props => <Component {...props} />} />;
};

export default PrivateRoute;
