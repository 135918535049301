import { init } from '@rematch/core';
import selectPlugin from '@rematch/select';
import createLoadingPlugin from '@rematch/loading';
import * as models from './models';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

const loadingPlugin = createLoadingPlugin();

export const history = createBrowserHistory();

const reducers = { router: connectRouter(history) };

const store = init({
  redux: {
    reducers,
    middlewares: [routerMiddleware(history)],
    devtoolOptions: {},
  },
  models,
  plugins: [
    selectPlugin({
      sliceState: (rootState, model) => ({
        ...rootState[model.name],
        loading: rootState.loading.effects[model.name],
      }),
    }),
    loadingPlugin,
  ],
});

export default store;
