function hexToRgb(hex) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
}

function opacity(hex, a = 100) {
  const rgb = hexToRgb(hex);
  if (!rgb) return;

  const { r, g, b } = rgb;
  return `rgba(${r},${g},${b},${a / 100})`;
}
export default opacity;
