export const CRYPTO_BADGE_URL = process.env.REACT_APP_cryptobadge_url;
export const CIF_URL = process.env.REACT_APP_cif_url;
export const CANPASS_URL = process.env.REACT_APP_BASE_URL;

export const POST_MESSAGE_TYPE = {
  LOGIN: 'login',
  LOGOUT: 'logout',
};
export const LOCAL_STORAGE_SELF_LOGGED_IN = 'self-logged-in';
export const CAN_ID_TOKEN = 'can-id-token';

export const CAN_KEYS_PUBLIC_KEYS =
  '-----BEGIN RSA PUBLIC KEY-----\nMIIBCgKCAQEAgNzh19Vm5kqRJxFfiwP0DTiS40wqZgizHzHKlg5WZY1OjPMn+nQp\nVvu4m6wV6H3ciklnCZniM3jJhzyrjHUqRgIWJ+g78mkq38YuMvmiBSBvdPu+RYtN\n+rgVsnkzVI9KY1iqa2XIjEF5riNe46NHbiqu8qfqeQL8KH/S1JvEvIOOw3ech/TN\n9VYDIczZxpTW7UfLIaRAX1WNOda22wNCEx03Cg6mqvt+GjlsxZk+O/rdbgwSLCIi\nNMNYuSvpeyqTcL4WtqvQAkxPblHY944UDAfVROYAhWQrZG53zrhUzGCEzArXpF8P\nNyT0v+TmyVlpQWhWYWQqBW5Se8n834mFfQIDAQAB\n-----END RSA PUBLIC KEY-----';
