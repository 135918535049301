import PrivateRoute from 'Containers/Routes/PrivateRoutes';
import React, { useState } from 'react';
import Header from '../Header';
import Sidebar from '../Sidebar';
import styled from 'styled-components/macro';
import { media } from '../../Theme/constants';
import { Mobile } from '../../Utils/MediaQuery';
import menuIcon from '../../Assets/Images/ic-menu.svg';
import expandIcon from '../../Assets/Images/ic-slidearrow-right.svg';

const Main = styled.main`
  padding-top: 4.5rem;
  @media (min-width: ${media.tablet}) {
    padding-top: 6rem;
    margin-left: 22rem;
    &.sidebar-collapsed {
      margin-left: 5.5rem;
    }
  }
`;

const Section = styled.section`
  width: 100%;
  overflow-x: hidden;
`;

const Body = styled.div`
  margin-top: 4.4rem;
`;

const SidebarStyled = styled.section`
  position: fixed;
  top: 4.5rem;
  left: 0;
  bottom: 0;
  width: 22rem;
  background-color: #fafafa;
  z-index: 99;
  transform: translate(-100%, 0);
  transition: 0.2s ease-in-out;
  &.sidebar-collapsed {
    transform: translate(0, 0);
    transition: 0.2s ease-in-out;
  }
  @media (min-width: ${media.tablet}) {
    transform: translate(0, 0);
    transition: none;
    &.sidebar-collapsed {
      width: 5.5rem;
      transition: none;
      ul {
        li {
          span {
            display: none;
          }
          img {
            margin: 0 auto;
          }
        }
      }
      .cp-toggle-btn {
        transform: rotate(0);
      }
      .cp-head {
        h5 {
          display: none;
        }
        img {
          margin: 0 auto;
        }
      }
    }
  }
  @media (min-width: ${media.desktop}) {
    top: 6rem;
  }
`;

const IconButton = styled.button`
  border: none;
  outline: none;
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  height: 3.6rem;
  width: 3.6rem;
  background-color: #e5e5e5;
  border-radius: 50%;
  padding: 0.8rem;
  transform: rotate(180deg);
  img {
    margin: 0 auto;
  }
  &:hover {
    border-radius: 50%;
  }
`;

const SidebarRoute = ({ ...rest }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <React.Fragment>
      <Header />
      <Mobile>
        <IconButton type="button" onClick={() => setCollapsed(!collapsed)}>
          <img src={menuIcon} alt="icon" />
        </IconButton>
      </Mobile>
      <SidebarStyled className={collapsed ? 'sidebar-collapsed' : ''}>
        <Sidebar />
        <IconButton
          className="cp-toggle-btn"
          type="button"
          onClick={() => setCollapsed(!collapsed)}
        >
          <img src={expandIcon} alt="icon" />
        </IconButton>
      </SidebarStyled>
      <Main className={collapsed ? 'sidebar-collapsed' : ''}>
        <Section>
          <Body>
            <PrivateRoute {...rest} />
          </Body>
        </Section>
      </Main>
    </React.Fragment>
  );
};

export default SidebarRoute;
