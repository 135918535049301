import api from './api';
import qs from 'qs';

async function createApplication(applicationName, applicationUrl) {
  const res = await api.post(
    '/oauth2/apps/',
    qs.stringify({
      application_name: applicationName,
      application_url: applicationUrl,
    }),
  );
  return res.data;
}

async function getApplicationById(id) {
  const res = await api.get(`/oauth2/apps/${id}`);
  return res.data;
}

async function getApplications() {
  const res = await api.get(`/oauth2/apps`);
  return res.data;
}

async function updateApplication(
  application_id,
  application_name,
  application_url,
  app_icon,
  app_favicon,
  theme,
  policy_url,
  terms_of_service_url,
  auth_kakao_client_id,
  auth_kakao_client_secret,
  is_consent_display,
  is_14_years_old_required,
  consent_type,
  auth_google_client_id,
  auth_google_client_secret,
  auth_providers,
  auth_methods,
  version,
  background_color,
  metamask_sign_message,
  auth_apple_client_id,
  auth_apple_team_id,
  auth_apple_secret_key_id,
  auth_apple_client_secret,
  auth_facebook_client_id,
  auth_facebook_client_secret,
  auth_discord_client_id,
  auth_discord_client_secret,
  auth_twitter_client_id,
  auth_twitter_client_secret,
) {
  const res = await api.put(
    `/oauth2/apps/${application_id}`,
    qs.stringify({
      application_name,
      application_url,
      app_icon: app_icon ? app_icon : undefined,
      app_favicon: app_favicon ? app_favicon : undefined,
      theme,
      policy_url,
      terms_of_service_url,
      auth_kakao_client_id,
      auth_kakao_client_secret,
      is_consent_display,
      is_14_years_old_required,
      consent_type,
      auth_google_client_id,
      auth_google_client_secret,
      auth_providers: JSON.stringify(auth_providers),
      auth_methods: JSON.stringify(auth_methods),
      version,
      background_color,
      metamask_sign_message,
      auth_apple_client_id,
      auth_apple_team_id,
      auth_apple_secret_key_id,
      auth_apple_client_secret,
      auth_facebook_client_id,
      auth_facebook_client_secret,
      auth_discord_client_id,
      auth_discord_client_secret,
      auth_twitter_client_id,
      auth_twitter_client_secret,
    }),
  );
  return res.data;
}

async function createCANAccount(appId) {
  const res = await api.post(`/oauth2/apps/${appId}/can_account`);
  return res;
}

export {
  getApplicationById,
  getApplications,
  createApplication,
  updateApplication,
  createCANAccount,
};
