import api from './api';

async function createApiKeys(appId) {
  const res = await api.post(`/oauth2/apps/${appId}/keys`);

  return res.data;
}

async function getApiKeysByAppId(appId) {
  if (!appId) return [];
  const res = await api.get(`/oauth2/apps/${appId}/keys`);

  return res.data;
}

async function deleteApiKeyByAppIdKeyId({ appId, keyId }) {
  const res = await api.delete(
    `/oauth2/apps/${appId}/keys/${encodeURIComponent(keyId)}`,
  );
  return res;
}

export { createApiKeys, getApiKeysByAppId, deleteApiKeyByAppIdKeyId };
