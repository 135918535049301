import { getUserInfo } from '../../Services/CANpass';
import { push } from 'connected-react-router';
import { CAN_ID_TOKEN } from 'Utils/constants';

import { getCookie, removeCookie } from 'Utils/docCookies';
import avatar from 'Assets/Images/ic-none-profile.svg';

const credentialsInit = {
  userAttributes: { userAttributes: avatar },
  canIdToken: '',
};
export const credentials = {
  state: {
    ...credentialsInit,
  },
  reducers: {
    resetCredentials() {
      return credentialsInit;
    },
    setCurrentSession(state, payload) {
      return {
        ...state,
        userAttributes: payload,
      };
    },
    setCanIdToken(state, payload) {
      return {
        ...state,
        canIdToken: payload,
      };
    },
  },
  effects: dispatch => ({
    async logOutAsync() {
      try {
        this.resetCredentials();
        dispatch.application.clearStore();
        dispatch.client.clearStore();
        removeCookie(CAN_ID_TOKEN);

        dispatch(push('/login'));
      } catch (e) {
        dispatch.notification.openAsync(e.message);
      }
    },
    async getCurrentSession() {
      try {
        const canIdToken = getCookie(CAN_ID_TOKEN);
        if (canIdToken) {
          this.setCanIdToken(canIdToken);
          const result = await getUserInfo();
          this.setCurrentSession(result);

          dispatch(push('/'));
        } else {
          dispatch(push('/login'));
        }
      } catch (e) {
        console.log(e);
        dispatch(push('/login'));
      }
    },
  }),

  selectors: slice => ({
    isLoggedInSelector() {
      return slice(state => !!state.canIdToken && state.userAttributes.name);
    },
    isGettingCurrentSessionSelector() {
      return slice(state => state.loading.getCurrentSession);
    },
    userAttributesSelector() {
      return slice(state => state.userAttributes);
    },
    canIdTokenSelector() {
      return slice(state => state.canIdToken);
    },
  }),
};
