import { createGlobalStyle } from 'styled-components/macro';
import { media } from '../../Theme/constants';

export const FONT_FAMILY = 'Helvetica, Arial, Roboto, sans-serif';

const Typography = createGlobalStyle`
  html {
    font-size: calc((10px + ((100vw - 375px) / 375) * 10));
  }
  body {
    font-size: 1.2rem;
    line-height: 1.6em;
  }
  html, input, textarea {
    font-family: ${FONT_FAMILY};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
  }

  h1 {
    font-size: 3rem;
  }

  h2 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1.9rem;
  }

  h4 {
    font-size: 1.8rem;
  }

  h5 {
    font-size: 1.6rem;
  }

  h6 {
    font-size: 1.4rem;
  }
  p{
    margin-bottom: 0.8rem;  
  }
  
  @media (min-width: ${media.tablet}) {
    html {
       font-size: 10px;
    }
  }

`;

export default Typography;
