const enTranslationMessages = require('./Lang/en.json');
const koTranslationMessages = require('./Lang/ko.json');

// prettier-ignore
const appLocales = [
  'en',
  'ko',
];

const DEFAULT_LOCALE = 'en';

const formatTranslationMessages = (locale, messages) => {
  const defaultFormattedMessages =
    locale !== DEFAULT_LOCALE
      ? formatTranslationMessages(DEFAULT_LOCALE, enTranslationMessages)
      : {};
  const flattenFormattedMessages = (formattedMessages, key) => {
    const formattedMessage =
      !messages[key] && locale !== DEFAULT_LOCALE
        ? defaultFormattedMessages[key]
        : messages[key];
    return Object.assign(formattedMessages, { [key]: formattedMessage });
  };
  return Object.keys(messages).reduce(flattenFormattedMessages, {});
};

const translationMessages = {
  en: formatTranslationMessages('en', enTranslationMessages),
  ko: formatTranslationMessages('ko', koTranslationMessages),
};

export {
  translationMessages,
  appLocales,
  formatTranslationMessages,
  DEFAULT_LOCALE,
};
