import React, { useState } from 'react';
import styled from 'styled-components';

import iconClose from 'Assets/Images/24-ic-close.svg';
import iconSearch from 'Assets/Images/ic-search.svg';

const Search = styled.div`
  .searchInputs {
    display: flex;
    align-items: center;
    border-radius: 0.5rem;
    margin: 0 0.8rem;
    overflow: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0 0.3rem 0.8rem;
  }

  input {
    height: 3rem;
    width: 90%;
    font-size: 1.4rem;
    padding: 1.6rem 1.6rem 1.6rem 0.8rem;
    outline: none;
    border: none;
  }

  img {
    padding-left: 0.4rem;
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
  }
`;

const SearchBar = ({ placeholder, data, setData }) => {
  const [wordEntered, setWordEntered] = useState('');

  const handleFilter = event => {
    const searchWord = event.target.value;
    setWordEntered(searchWord);
    setData(
      data.filter(item =>
        item.application_name
          .toLowerCase()
          .includes(event.target.value.toLowerCase()),
      ),
    );
    //
    console.log(data);
  };

  const clearInput = () => {
    setWordEntered('');
    setData(data);
  };

  return (
    <Search>
      <div className="searchInputs">
        <input
          type="text"
          placeholder={placeholder}
          value={wordEntered}
          onChange={handleFilter}
        />
        {wordEntered ? (
          <img
            src={iconClose}
            alt="Icon clsoe"
            id="clearBtn"
            onClick={clearInput}
          />
        ) : (
          <img src={iconSearch} alt="Icon search" />
        )}
      </div>
    </Search>
  );
};

export default SearchBar;
