import api from './api';
import qs from 'qs';

async function createClient(
  applicationId,
  clientName,
  isTrusted,
  isPublic,
  redirectUri,
  grantTypes,
) {
  const res = await api.post(
    'oauth2/clients/',
    qs.stringify({
      application_id: applicationId,
      client_name: clientName,
      is_trusted: isTrusted,
      is_public: isPublic,
      redirect_uri: redirectUri,
      grant_type: grantTypes,
    }),
  );
  return res.data;
}

async function getClientById(id) {
  const res = await api.get(`/oauth2/clients/${id}`);
  return res.data;
}

async function getAppById(id) {
  const res = await api.get(`/oauth2/apps/${id}`);
  return res.data;
}

async function updateClient(
  client_id,
  client_name,
  redirect_uris,
  grant_types,
  isTrusted,
) {
  const res = await api.put(
    `/oauth2/clients/${client_id}`,
    qs.stringify({
      client_name: client_name,
      is_trusted: isTrusted,
      redirect_uri: redirect_uris,
      grant_type: grant_types,
    }),
  );
  return res.data;
}

async function resetClientSecret(clientId, clientSecret) {
  const res = await api.post(
    `/oauth2/clients/${clientId}/reset_secret`,
    qs.stringify({
      client_secret: clientSecret,
    }),
  );
  return res.data;
}

async function getClientsByApplicationId(applicationId) {
  const res = await api.get(`/oauth2/clients/apps/${applicationId}`);
  return res.data;
}

export {
  createClient,
  getClientById,
  getAppById,
  updateClient,
  resetClientSecret,
  getClientsByApplicationId,
};
