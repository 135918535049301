import Cookies from 'js-cookie';

const cookieAttrs = {
  path: '/',
  expires: 3, // set expired time to 3 days
  domain: process.env.REACT_APP_cookie_domain,
};

export const removeCookie = sKey => Cookies.remove(sKey, cookieAttrs);
export const getCookie = name => Cookies.get(name);
