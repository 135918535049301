import { getTx, signTx, getSession } from 'API/transaction';

export const transaction = {
  state: {
    details: null,
    hasSession: false,
    redirectBack: '',
  },
  reducers: {
    setDetails(state, payload) {
      return {
        ...state,
        details: payload,
      };
    },
    setHasSession(state, payload) {
      return {
        ...state,
        hasSession: payload,
      };
    },
    setRedirectBack(state, payload) {
      return {
        ...state,
        redirectBack: payload,
      };
    },
  },
  effects: () => ({
    async getTransactionAsync(payload) {
      try {
        const { txId } = payload;
        const tx = await getTx(txId);
        this.setDetails(tx);
        return tx;
      } catch (err) {
        console.log('--- getTransactionAsyncError', err);
      }
    },

    async signTransactionAsync(payload) {
      const { txId, secretCode, broadcast } = payload;
      return signTx(txId, secretCode, broadcast);
    },
    async getSessionAsync() {
      try {
        const res = await getSession();
        this.setHasSession(res);
      } catch (err) {
        console.log('--- getSessionAsyncError', err);
      }
    },
  }),
  selectors: slice => ({
    details() {
      return slice(state => state.details);
    },
    hasSession() {
      return slice(state => state.hasSession);
    },
    isSigning() {
      return slice(state => state.loading.signTransactionAsync);
    },
  }),
};
