import axios from 'axios';
import store from '../Store';

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

api.interceptors.request.use(
  function(config) {
    const canIdToken = store.select.credentials.canIdTokenSelector(
      store.getState(),
    );
    if (canIdToken) config.headers['Can-Id-Token'] = canIdToken;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  },
);

export default api;
