import { graphql } from '../Utils/graphql';
import { CANPASS_URL } from '../Utils/constants';

export async function getUserInfo() {
  const response = await graphql(CANPASS_URL + '/api/graphql', {
    query: `query {
      me {
        canAccount
        name
        picture
        email
      }
    }
    `,
  });

  return response.me;
}
