import store from '../Store';

export const graphql = (endPoint, { query, variables, headers }) => {
  const canIdToken = store.select.credentials.canIdTokenSelector(
    store.getState(),
  );
  const customizedHeader = {
    Authorization: 'Bearer ' + canIdToken,
  };

  return fetch(endPoint, {
    method: 'POST',
    headers: {
      ...customizedHeader,
      ...headers,
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  })
    .then(res => res.json())
    .then(data => {
      if (data.errors) {
        throw data.errors;
      } else {
        return data.data;
      }
    });
};
