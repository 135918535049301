import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import avatar from 'Assets/Images/ic-none-profile.svg';

const AvatarStyled = styled.img`
  width: 100%;
  box-shadow: rgba(42, 42, 42, 0.11) 0.1rem 0.5rem 0.5rem 0;
  border-radius: 50%;
  object-fit: cover;
  height: 100%;
`;

export const Avatar = props => {
  const { src, alt, ...rest } = props;
  return <AvatarStyled src={src ? src : avatar} alt={alt} {...rest} />;
};

Avatar.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
};

Avatar.defaultProps = {
  src: avatar,
  alt: 'avatar',
};
