import React from 'react';
import { Route } from 'react-router-dom';
import Header from '../Header';

const DefaultRoute = ({ component: Component, ...rest }) => {
  return (
    <React.Fragment>
      <Header />
      <Route {...rest} render={matchProps => <Component {...matchProps} />} />
    </React.Fragment>
  );
};

export default DefaultRoute;
