import Storage from '@aws-amplify/storage';
//import { fetchAPI } from '../Utils/graphql';

async function getAvatar(picture) {
  if (!picture.key) return '';
  try {
    const link = await Storage.get(picture.key, {
      level: 'public',
      ...picture,
    });
    if (link) {
      const endOfLink = link.indexOf('?');
      return link
        .substring(0, endOfLink)
        .replace(
          'https://crypto-badge-static-prod.s3.us-west-2.amazonaws.com',
          'https://d804z73gtqcb.cloudfront.net',
        );
    }
  } catch (e) {
    /* eslint-disable-next-line */
    console.log('---- error', e);
  }
  return '';
}
async function upload(key, object, options) {
  const rs = await Storage.put(key, object, options);
  return { key: rs.key, options };
}

async function uploadFile(file, level = 'public') {
  const fileInput = file.file;
  const ext = fileInput.name.split('.').pop();
  const nameBuilt = `${fileInput.name.replace(
    `.${ext}`,
    '',
  )}-${Date.now()}.${ext}`;

  // Get signed url from server to upload to S3
  // const data = await fetchAPI(
  //   'https://dev.api.cryptobadge.xyz/m1/getS3FileUploadUrl',
  //   {
  //     body: { fileName: nameBuilt },
  //   },
  // );

  // Upload file
  // return fetch(data.uploadUrl, {
  //   method: 'PUT',
  //   body: fileInput,
  // }).then(res => res);
  return upload(nameBuilt, fileInput, {
    level,
    contentType: fileInput.type,
  });
}

export { getAvatar, uploadFile };
