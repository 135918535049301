import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { IntlProvider } from 'Providers/IntlProvider';
import { version } from '../../../package.json';

import Routes from '../Routes/Routes';
import { GlobalStyle, Sanitize, Typography } from 'Components/Styles';
import store from 'Store';
import { HelmetProvider } from 'react-helmet-async';

store.dispatch.credentials.getCurrentSession();

function App() {
  useEffect(() => {
    console.log('CANpass version: ', version);
  }, []);

  return (
    <HelmetProvider>
      <Sanitize />
      <Typography />
      <GlobalStyle />
      <Provider store={store}>
        <IntlProvider>
          <Routes />
        </IntlProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
