import { push } from 'connected-react-router';
import {
  createClient,
  getClientById,
  getAppById,
  getClientsByApplicationId,
  resetClientSecret,
  updateClient,
} from '../../Services/client';
import { get } from 'lodash';

const clientInit = {
  clientDetail: {},
  appDetail: {},
  clients: [],
};

export const client = {
  state: clientInit, // initial state
  reducers: {
    updateClientDetail(state, payload) {
      return { ...state, clientDetail: payload };
    },
    updateAppDetail(state, payload) {
      return { ...state, appDetail: payload };
    },
    updateClients(state, payload) {
      return { ...state, clients: payload };
    },
    updateClientSecretId(state, payload) {
      return {
        ...state,
        clientDetail: { ...state.clientDetail, client_secret: payload },
      };
    },
    clear() {
      return { clientInit };
    },
  },
  effects: dispatch => ({
    async createClient(action) {
      try {
        const {
          applicationId,
          clientName,
          isTrusted = false,
          isPublic,
          redirectUri,
          grantTypes,
        } = action;
        await createClient(
          applicationId,
          clientName,
          isTrusted,
          isPublic,
          redirectUri,
          grantTypes,
        );
        dispatch.notification.openAsync('Create client successfully!');

        dispatch(push(`/oauth2/clients?appId=${applicationId}`));
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
      }
    },
    async fetchClientDetail(clientId) {
      try {
        const data = await getClientById(clientId);
        dispatch.client.updateClientDetail(data);
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        if (e.message.includes('404')) {
          window.location.href = `/oauth2/clients${window.location.search}`;
        }
        console.error(e);
      }
    },

    async fetchAppDetail(appId) {
      try {
        const data = await getAppById(appId);
        console.log('fetchAppDetail -> data', data);
        dispatch.client.updateAppDetail(data);
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        console.error(e);
      }
    },

    async fetchClients(applicationId) {
      try {
        const data = await getClientsByApplicationId(applicationId);
        dispatch.client.updateClients(data);
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        console.error(e);
      }
    },
    async resetClientSecretId(client_id) {
      try {
        const data = await resetClientSecret(client_id);
        dispatch.client.updateClientSecretId(data.client_secret);
        dispatch.notification.openAsync('Reset client secret successfully!');
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        console.error(e);
      }
    },

    async updateClientInfo(action) {
      try {
        const {
          client_id,
          client_name,
          redirect_uris,
          grant_types,
          is_trusted,
        } = action;
        const data = await updateClient(
          client_id,
          client_name,
          redirect_uris,
          grant_types,
          is_trusted,
        );

        const payload = {
          ...data,
          redirect_uris: data.redirectUris,
        };
        dispatch.client.updateClientDetail(payload);
        dispatch.notification.openAsync('Update client successfully!');
      } catch (e) {
        dispatch.notification.openAsync(
          get(e.response, 'data.error_description'),
        );
        console.error(e);
      }
    },
    async clearStore() {
      dispatch.client.clear();
    },
  }),

  selectors: slice => ({
    clientSelectors() {
      return slice(state => get(state, 'clients'));
    },
    clientDetailSellector() {
      return slice(state => get(state, 'clientDetail'));
    },
    appDetailSelector() {
      return slice(state => get(state, 'appDetail'));
    },
  }),
};
