import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { colors } from '../../Theme/constants';
import { Spinner } from '../Spinner';

const ButtonStyled = styled.button`
  background-color: ${props => props.backgroundColor};
  border: 0.1rem solid ${props => props.backgroundColor};
  border-radius: ${props => props.borderRadius}rem;
  color: ${props => props.color};
  height: ${props => props.height}rem;
  line-height: ${props => props.height}rem;
  font-size: ${props => props.fontSize}rem;
  padding: 0 2.5rem;
  box-sizing: content-box;
  width: ${props => props.width};
  &.isLoading {
    position: relative;
    span {
      opacity: 0;
    }
    .cp-spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
`;

export const Button = props => {
  const {
    className,
    children,
    backgroundColor,
    color,
    height,
    borderRadius,
    fontSize,
    disabled,
    width,
    isLoading,
    ...rest
  } = props;
  return (
    <ButtonStyled
      backgroundColor={backgroundColor}
      color={color}
      height={height}
      borderRadius={borderRadius}
      fontSize={fontSize}
      disabled={disabled || isLoading}
      width={width}
      className={`${className} ${isLoading ? 'isLoading' : ''}`}
      {...rest}
    >
      <span>{children}</span>
      {isLoading && <Spinner className="cp-spinner" />}
    </ButtonStyled>
  );
};

Button.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.number,
  borderRadius: PropTypes.number,
  fontSize: PropTypes.number,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  isLoading: false,
  backgroundColor: colors.blue,
  color: colors.white,
  height: 3.6,
  borderRadius: 0.5,
  fontSize: 1.4,
  width: 'auto',
  className: '',
};
