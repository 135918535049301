import { createGlobalStyle } from 'styled-components/macro';
import { colors } from '../../Theme/constants';

const GlobalStyle = createGlobalStyle`
  body {
    color: ${colors.black};
  }
  input,
  textarea {
    outline: none;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: inherit;
      transition-property: all;
      transition-delay: 99999s;
      -webkit-transition-delay: 99999s;
    }
  }

  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  button {
    outline: none;
    cursor: pointer;
    &:disabled {
      cursor: default;
    }
  }
  table {
    margin: 0;
    padding: 0;
    border: none;
    width: 100%;
    tr {
      padding: 0;
      margin: 0;
    }
    th {
      font-style: normal;
      padding: 0;
      margin: 0;
    }
    td {
      padding: 0;
      margin: 0;
    }
  }
  a {
    text-decoration: none;
    &:hover {
      text-decoration: none;
    }
  }
  img {
    max-width: 100%;
    display: block;
  }
  .overFlowHidden{
    overflow: hidden;
  }
  
`;

export default GlobalStyle;
