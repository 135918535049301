import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components/macro';
import { colors } from '../../Theme/constants';

const SpinnerStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    border-radius: 50%;
    display: inline-block;
    animation: 0.7s spin linear infinite;
    width: ${props => props.size}rem;
    height: ${props => props.size}rem;
    border: solid ${props => props.borderColor};
    border-width: ${props => props.borderWidth}rem;
    border-bottom-color: transparent;
    @keyframes spin {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Spinner = props => {
  const { className, borderColor, borderWidth, size } = props;

  return (
    <SpinnerStyled
      className={className}
      borderColor={borderColor}
      borderWidth={borderWidth}
      size={size}
    >
      <i />
    </SpinnerStyled>
  );
};

Spinner.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
  borderColor: PropTypes.string,
  borderWidth: PropTypes.number,
};

Spinner.defaultProps = {
  className: '',
  size: 1.6,
  borderColor: colors.white,
  borderWidth: 0.2,
};
