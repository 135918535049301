export const colors = {
  blue: '#0086ff',
  blue900: '#001563',
  yellow: '#ffff00',
  white: '#ffffff',
  black: '#000000',
  grey: '#a2a2a2',
  grey200: '#f8f8f8',
  red: '#fb2942',
  lightBlue: '#20639b1c',
  darkBlue: '#173f5f',
};
export const media = {
  tablet: '767px',
  desktop: '1080px',
};
