import React, { useEffect } from 'react';
import styled from 'styled-components/macro';
import { colors } from '../../Theme/constants';
import opacity from '../../Utils/opacity';

const ModalWrapperStyled = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: ${opacity(colors.black, 60)};
  z-index: 102;
  display: flex;
  align-items: center;
  justify-content: center;
`;

function ModalWrapper(props) {
  const { children } = props;

  useEffect(() => {
    document.body.classList.add('overFlowHidden');
    return () => {
      document.body.classList.remove('overFlowHidden');
    };
  });

  return <ModalWrapperStyled>{children}</ModalWrapperStyled>;
}

export default ModalWrapper;
